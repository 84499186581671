import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/home/obyte/obyte-explorer-frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/home/obyte/obyte-explorer-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/home/obyte/obyte-explorer-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/home/obyte/obyte-explorer-frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/home/obyte/obyte-explorer-frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/home/obyte/obyte-explorer-frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/home/obyte/obyte-explorer-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/home/obyte/obyte-explorer-frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/home/obyte/obyte-explorer-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/home/obyte/obyte-explorer-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin__pU5O5C_VbsaGGSmb4VRbMHXmeLBDloAvAuUQb8JGdU from "/home/obyte/obyte-explorer-frontend/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw from "/home/obyte/obyte-explorer-frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM from "/home/obyte/obyte-explorer-frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import error_handler_DNMY46R1VrmPTze2vUP_MqY_D_J1FfFG_ohDUaaTsSU from "/home/obyte/obyte-explorer-frontend/plugins/error-handler.ts";
import socketio_54hT660XFm_j78N2gBJiiFLkn_7pzrWNP_RtoAu7imI from "/home/obyte/obyte-explorer-frontend/plugins/socketio.ts";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin__pU5O5C_VbsaGGSmb4VRbMHXmeLBDloAvAuUQb8JGdU,
  switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw,
  i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM,
  error_handler_DNMY46R1VrmPTze2vUP_MqY_D_J1FfFG_ohDUaaTsSU,
  socketio_54hT660XFm_j78N2gBJiiFLkn_7pzrWNP_RtoAu7imI
]