
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91unit_93sdU5WnQWXtvWxBVSFgD5CbYAUXojEr2GLIDIX31NwE0Meta } from "/home/obyte/obyte-explorer-frontend/pages/[unit].vue?macro=true";
import { default as _91address_93w19fsz_45vHkvdvKKhJ08QI88wWgIculS4OkPIKfajxDMMeta } from "/home/obyte/obyte-explorer-frontend/pages/address/[address].vue?macro=true";
import { default as _91asset_93DBo1zPZFe7kVOO_AcNg0cNr4rRxJFAJP8w2dJQ3V2MEMeta } from "/home/obyte/obyte-explorer-frontend/pages/asset/[asset].vue?macro=true";
import { default as indexU82Ka7wUxRMocZyE5nrR29RgTAY0hMES_OjekWtxm6sMeta } from "/home/obyte/obyte-explorer-frontend/pages/index.vue?macro=true";
export default [
  {
    name: "unit",
    path: "/:unit+",
    meta: _91unit_93sdU5WnQWXtvWxBVSFgD5CbYAUXojEr2GLIDIX31NwE0Meta || {},
    component: () => import("/home/obyte/obyte-explorer-frontend/pages/[unit].vue")
  },
  {
    name: "address-address",
    path: "/address/:address()",
    component: () => import("/home/obyte/obyte-explorer-frontend/pages/address/[address].vue")
  },
  {
    name: "asset",
    path: "/asset/:asset(.*)",
    meta: _91asset_93DBo1zPZFe7kVOO_AcNg0cNr4rRxJFAJP8w2dJQ3V2MEMeta || {},
    component: () => import("/home/obyte/obyte-explorer-frontend/pages/asset/[asset].vue")
  },
  {
    name: "home",
    path: "/",
    meta: indexU82Ka7wUxRMocZyE5nrR29RgTAY0hMES_OjekWtxm6sMeta || {},
    component: () => import("/home/obyte/obyte-explorer-frontend/pages/index.vue")
  }
]